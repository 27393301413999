.ActionDialogConfig {
    width: 360px; /* Fixed width */
    height: 100%; /* Adjust based on your layout */
    overflow-y: scroll; /* Always show the vertical scrollbar */
    overflow-x: hidden; /* Prevent horizontal scrolling */
    scrollbar-width: thin; /* For Firefox */
    -ms-overflow-style: scrollbar; /* For IE and Edge */
}

.ActionDialogConfig::-webkit-scrollbar {
    width: 8px; /* Consistent width for Chrome, Safari, and Opera */
}

.ActionDialogConfig::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.2); /* Optional styling for the thumb */
    border-radius: 4px;
}

.ActionDialogConfig::-webkit-scrollbar-track {
    background: transparent; /* Optional styling for the track */
}
