.ElementConfiguration {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    overflow: auto;
}

.ElementConfiguration-subPage {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 200;
}