.UserMenu{
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 30px;
}
.UserMenu-userItem {
    border-radius: 50%;
    width: 70px;
    height: 70px;
}
.UserMenu-icon {
    width: 100%;
    height: 100%;
}
.UserMenu-menu {
    position: absolute;
    background-color: #d4af37;
    border-radius: 15px;
    width: 80%;
    top: -150px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    flex-direction: column;
    justify-items: center;
    justify-content: center;
    padding-bottom: 10px;
}
.UserMenu-menuButton {
    all: unset;
    background-color: transparent;
    color: black;
    text-align: center;
    margin-top: 10px;
    height: 40px;
}
.UserMenu-menuButton:hover {
    background-color: #092635;
    color: white;
}
