.ActionDialog {
    width: 100%;
    height: 100%;
    overflow: auto;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    background-color: #333333;
    padding: 20px;
    box-sizing: border-box;
}

.ActionDialog-content {
    display: flex;
    flex-direction: column;
    align-items: center;
}


