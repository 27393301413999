.DialogPickerItem {
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #9e9e9e;
    cursor: pointer;
}

.DialogPickerItem:hover {
    background-color: #444444;
}