.SignupButton{
    all: unset;
    border-radius: 10px;
    width: 300px;
    height: 50px;
    background-color: #d4af37;
    color: white;
    font-size: 20px;
    text-align: center;
    border: white 1px solid;
}