.cube-container {
    width: 100%;
    height: 100%;
    perspective: 200000px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    top: 0;
    left: 0;
    z-index: 500;
}

.cube {
    z-index: 500;
    width: 100%;
    height: 100%;
    position: absolute;
    transform-style: preserve-3d;
    transform-origin: center;
}

/* Initial tilt applied here */
.weird .cube {
    animation: 4s weird infinite ease-in-out;
    transform: rotateZ(45deg) rotateY(45deg) rotateX(45deg);

}

.multispin .cube {
    animation: multiSpin 20s infinite linear;
}

.rotate .cube {
    animation: 4s rotate infinite linear;
    transform: translateZ(0px) rotateZ(0deg) rotateY(0deg) rotateX(0deg);
}


@keyframes rotate {
    0% {
        transform: rotateX(0deg) rotateY(0deg) rotateZ(0deg);
    }
    33% {
        transform: rotateX(120deg) rotateY(120deg) rotateZ(120deg);
    }
    66% {
        transform: rotateX(240deg) rotateY(240deg) rotateZ(240deg);
    }
    100% {
        transform: rotateX(360deg) rotateY(360deg) rotateZ(360deg);
    }
}

@keyframes weird {
    to {
        transform: rotateZ(405deg) rotateY(405deg) rotateX(405deg);
    }
}

@keyframes multiSpin {
    0% {
        transform: rotateX(0deg) rotateY(0deg) rotateZ(0deg);
    }
    33% {
        transform: rotateX(120deg) rotateY(120deg) rotateZ(120deg);
    }
    66% {
        transform: rotateX(240deg) rotateY(240deg) rotateZ(240deg);
    }
    100% {
        transform: rotateX(360deg) rotateY(360deg) rotateZ(360deg);
    }
}

.cube-side {
    position: absolute;
    width: 100%;
    height: 100%;
    text-align: center;
    font-size: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    box-sizing: border-box;
}


