.Page {
    width: 100%;
    height: 100%;
    display: flex;
}

.Page-content {
    position: relative;
    flex-grow: 1;
    height: 100%;
    overflow-x: hidden;
}

.dialog-root {
    position: absolute; /* Relative to .Page-content */
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: auto;
    display: flex;
    justify-content: center;
    align-items: center;
}


/* Backdrop has to be set like this because of a bug with draggable div in Calendar Component (CoverDialog) */
.dialog-root:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(10px); /* Increase blur for a stronger effect */
    background-color: rgba(33, 33, 33, 0.3); /* Slightly transparent white */
    border-radius: 15px; /* Optional: round corners for a softer look */
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2); /* Subtle shadow for depth */
    overflow: hidden; /* To ensure content doesn't spill outside rounded corners */
}