.Palace {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    user-select: none;
    overflow: hidden;
}

/* Make Toolbar stay at the top */
.Palace .ToolbarContainer {
    flex-shrink: 0; /* Prevent Toolbar from resizing */
    width: 100%;
}

/* Center Room component in the remaining space */
.Palace .RoomContainer {
    position: relative;
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}
