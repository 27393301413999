.AbilityShop {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 100;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(6px);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}
.AbilityShop-close {
    all: unset;
    width: 20px;
    height: 20px;
    margin: 10px;
    align-self: flex-end;
    background-color: transparent;
    color: white;
    font-size: 30px;
}
.shopItems-container {
    display: grid;
    grid-template-columns: repeat(4, 150px);
    grid-gap: 5px;
    justify-content: center;
    align-content: flex-start;
}
.AbilityShop-changeViewToggleButtonGroup {
    height: 50px;
}
.AbilityShop-changeViewToggleButton {
    width: 100px;
}