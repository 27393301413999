.Toolbar {

}

/* Apply white color to all icons inside IconButton */
.MuiIconButton-root .MuiSvgIcon-root {
    color: white;
}

/* Optionally, add hover or active state styles if needed */
.MuiIconButton-root:hover .MuiSvgIcon-root {
    color: orange; /* Change color on hover, optional */
}
