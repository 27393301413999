.Abilities {
    background-color: #092635;
    color: white;
    width: 100%;
    height: 100%;
    position: relative;
}

.Abilities-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, 150px);
    grid-gap: 5px;
    justify-content: center;
    align-content: flex-start;
}

.Abilities-openShop {
    margin: 30px;
    aspect-ratio: 1 / 1;
    border: 1px white solid;
    border-radius: 50%;
    background-color: transparent;
    color: white;
    font-size: 50px;
}