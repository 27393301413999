.TextEditorOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.TextEditor {
    background: #fff;
    padding: 10px;
    border-radius: 5px;
    width: 100vw;
    height: 100vh;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    display: flex;
    flex-direction: column;
}

.TextEditorArea {
    width: 100%;
    height: calc(100vh - 60px); /* Adjust for buttons at the bottom */
    resize: none; /* Optional: prevents textarea resizing */
    border: none;
    outline: none;
    font-family: inherit;
    font-size: 1rem;
}

.TextEditorActions {
    margin-top: auto;
    text-align: right;
}
