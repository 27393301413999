.Calendar {
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.fc {
    background-color: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.Calendar .fc-toolbar {
    font-size: 16px; /* Adjust as needed for smaller text */
    padding: 15px; /* Less padding for a compact look */
}

.Calendar .fc-today-button {
    margin-top: 1px; /* Adjust the value as needed */
    margin-right: 10px;
}

.Calendar .fc-button {
    padding: 4px 6px; /* Adjust button padding */
    font-size: 16px;
    border-radius: 8px;
}

.Calendar .fc-button:focus {
    box-shadow: none;
}

.fc .fc-button-primary:focus {
    box-shadow: none;
}

.fc .fc-button-primary:not(:disabled).fc-button-active:focus, .fc .fc-button-primary:not(:disabled):active:focus {
    box-shadow: none;
}

.Calendar .fc-toolbar-title {
    font-size: 18px; /* Increase font size */
    text-align: center; /* Center align title */
    flex-grow: 1;
}


.Calendar .fc-header-toolbar .fc-toolbar-chunk:first-child .fc-button-group {
    margin-right: 10px;
}

.Calendar .fc-header-toolbar .fc-toolbar-chunk:last-child .fc-button-group {
    margin-left: 10px;
}

.fc-direction-ltr .fc-toolbar > * > :not(:first-child) {
    margin-left: 9px;
}

.fc-event-time {
    display: none;
}

.fc-event {
    padding: 0px 2px;
    margin: 0px 1px;
    border-radius: 5px;

}

.fc-daygrid-dot-event .fc-event-title {
    font-weight: normal;
}

.fc-h-event {
    border: none;
}

.fc-timeGridWeek-view .fc-event {
    background-color: #2c3e50;
    color: white;
}

.fc-h-event {
    background-color: #2c3e50;
    color: white;
    border-color: #2c3e50;
}

.selected-event {
    border: 2px solid orange;
}





