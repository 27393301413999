.IconPicker {
    width: 500px;
    height: 100%;
    overflow: auto;
}

.IconPicker-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(50px, 1fr));
    gap: 5px;
    justify-content: center;
    align-content: flex-start;
}
