.Cover {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative; /* Allows absolute positioning for the title */
    background-color: rgba(255, 255, 255, 1);
    color: #666;
    width: 100%;
    height: 100%;
    text-align: center;
    box-sizing: border-box;
    padding: 10px;
    cursor: pointer;
}

.Cover-icon {
    font-size: 2rem; /* Adjust icon size as needed */
    display: flex;
}
