.Sidebar {
    height: 100%;
    color: white;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.Sidebar-link {
    text-decoration: none;
    color: inherit;
}

.Sidebar-list {
}