.CoverDialog {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}


.CoverDialog-box {
    position: relative;
    border-radius: 10px;
    transition: width 0.15s ease, height 0.15s ease;
    overflow: hidden;
}

/* Add the resize handle */
.CoverDialog-resizeHandle {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 15px;
    height: 15px;
    cursor: se-resize;
    background-color: #2c3e50;
    z-index: 1000;
}
