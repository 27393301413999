.Welcome {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 100;
    width: 100%;
    height: 100%;
    /*zack*/
    backdrop-filter: blur(6px);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: center;
    align-items: center;
    animation: fadeIn 0.4s ease-in-out;
}


.Welcome-box {
    display: flex;
    flex-direction: column;
    width: 400px;
    padding: 20px 20px 20px 20px;
    background-color: white;
    border-radius: 10px;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}