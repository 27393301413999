.File {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-bottom: 4px;
    position: relative;
}

.FileName {
    flex-grow: 1;
}

.FileActions {
    display: flex;
    gap: 4px;
    visibility: hidden; /* Hidden by default */
}

.File:hover .FileActions {
    visibility: visible; /* Show on hover */
}

.FileActionButton {
    cursor: pointer;
    color: white;
    transition: color 0.3s;
}

.FileActionButton:hover {
    color: #000;
}
