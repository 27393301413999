.samantha {
    position: absolute;
    z-index: 9999; /* Ensure it floats above all else */
    display: flex;
    align-items: center;
    justify-content: center;
    left: 50%;
    transform: translateX(-50%);
    top: 10%;
    pointer-events: none;
}

/* The blob now is absolutely positioned behind the content */
.samantha-blob {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    aspect-ratio: 1/1;
    z-index: 0;
    filter: blur(10px);
    background: linear-gradient(
            47deg,
            rgba(255, 88, 139, 1) 21%,
            rgb(0 164 173) 67%,
            rgba(118, 74, 166, 1) 81%
    );
    background-size: 200% 200%;
    /* Animate the blob: shape, rotation, scale, and color hue */
    animation: samantha-animate 10s cubic-bezier(0.68, -0.55, 0.27, 1.55) infinite alternate forwards, samantha-hue-shift 5s infinite;
}

.samantha-content {
    padding: 5px 40px;
    margin: 25px;
    position: relative;
    font-family: 'Sora', sans-serif;
    color: white;
    text-align: center;
    font-size: 1.2rem;
    z-index: 1; /* Ensure it's above the blob */
    filter: none; /* Ensure text isn't blurred */
    overflow: visible;
}

.samantha-content {
    /* If you previously had text-align:center somewhere, remove it or set it to left: */
    text-align: left;

}

.heading {
    display: inline-block; /* Makes the heading shrink-to-fit and thus centerable */
    text-align: left;
}


.actor__content {
    position: relative;
    line-height: 50px;
}

.actor__content--typing::after {
    content: '|';
    animation: blink 500ms infinite;
    position: absolute;
    right: -10px;
}

@keyframes blink {
    0%, 50% {
        opacity: 1;
    }
    51%, 100% {
        opacity: 0;
    }
}


@keyframes samantha-animate {
    0% {
        background-position: 10% 5%;
        transform: scale(1);
        border-radius: 10% 20% 10% 10% / 20% 10% 10% 10%;
    }
    25% {
        background-position: 25% 30%; /* Move the background around */
        transform: scale(1);
        border-radius: 7% 20% 35% 10% / 40% 10% 10% 5%;
    }
    50% {
        background-position: 65% 55%;
        transform: scale(1);
        border-radius: 3% 14% 10% 3% / 35% 5% 20% 15%;
    }
    75% {
        background-position: 36% 15%;
        transform: scale(1);
        border-radius: 5% 13% 13% 2% / 25% 20% 35% 18%;
    }
    100% {
        background-position: 45% 35%;
        transform: scale(1);
        border-radius: 8% 21% 12% 5% / 3% 3% 22% 23%;
    }
}

/* Continuous Hue Rotation */
@keyframes samantha-hue-shift {
    0% {
        filter: blur(25px) hue-rotate(0deg);
    }
    100% {
        filter: blur(25px) hue-rotate(360deg);
    }
}



