.DialogSwitch {
    width: 100%;
    display: flex;
    align-items: center; /* Changed from flex-end to center */
    position: relative;
}

.DialogSwitch-label {
    color: rgb(102, 102, 102);
    flex-shrink: 0;
    padding: 12px;
}

.DialogSwitch-line {
    flex-grow: 1;
    border-bottom: 1px dotted rgb(102, 102, 102);
    margin: 0 8px;
    height: 0;
}

.DialogSwitch .MuiSwitch-root {
    flex-shrink: 0;
}
