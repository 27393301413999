.Note {
    position: relative;
    overflow-y: auto;
    width: 100%;
    height: 100%;
    padding: 0;
}

/* Style the scrollbar */
.Note::-webkit-scrollbar {
    width: 10px; /* Width of the scrollbar */
}

.Note::-webkit-scrollbar-thumb {
    background-color: grey; /* Color of the scrollbar thumb */
    border-radius: 5px; /* Round corners of the scrollbar thumb */
}

.Note::-webkit-scrollbar-thumb:hover {
    background-color: darkgrey; /* Change color when hovered */
}

.Note::-webkit-scrollbar-track {
    background-color: #f1f1f1; /* Background color of the scrollbar track */
}

.Note-editor {
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    padding: 15px 15px;
}

.ce-settings .cdx-search-field {
    display: none;
}

.ce-toolbar__content {
    max-width: unset;
}

.ce-block__content {
    max-width: unset;
}

.ce-popover__container {
    position: absolute !important;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%) !important;
    background-color: #333333;
    color: white;
}

.codex-editor {
    position: unset;
}

.ce-toolbar__content {
    position: unset;
}

.ce-toolbar__actions {
    position: unset;
}

.ce-popover-item {
    color: white;
}

.ce-toolbar {
    top: unset !important;
    position: unset !important;
}

.ce-popover-item:hover:not(.ce-popover-item--no-hover) {
    background-color: #444444;
}

.cdx-search-field {
    background-color: #444444;
}

.cdx-search-field__input {
    color: white;
}

.ce-toolbar__plus {
    display: none;
}

.ce-toolbar__settings-btn {
    display: none;
}
