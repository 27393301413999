.Ability {
    border: white 1px solid;
    aspect-ratio: 1 / 1;
    margin: 10px;
    display: flex;
}

.Ability-coverImage {
    width: 100%;
    height: 100%;
}

.Ability-propertyButton {
    all: unset;
    width: 100%;
    height: 66%;
    background-color: #d4af37;
    text-align: center;
}

.Ability-unequipButton {
    all: unset;
    width: 100%;
    height: 33%;
    background-color: #1B4242;
    text-align: center;
}