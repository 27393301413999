.DataTableConfig {
    display: flex;
    flex-direction: column;
}

@keyframes fadeInOutError {
    0% {
        background-color: rgba(255, 0, 0, 0.2);
    }
    50% {
        background-color: rgba(255, 0, 0, 0.5);
    }
    100% {
        background-color: rgba(255, 0, 0, 0);
    }
}

.MuiTableCell-root {
    border-bottom: 1px solid grey !important;
}