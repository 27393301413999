/* Room.css */
.Room {

}

.movable-div {
    background-color: lightblue;
    position: absolute;
    cursor: move;
    resize: none;
}

.resize-handle {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 15px;
    height: 15px;
    background-color: #2c3e50;
    cursor: se-resize;
}



