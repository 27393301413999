/* Room.css */
.RoomCover {
    position: relative; /* Important so the SVG lines overlay this container */
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #2c3e50; /* Outer background */
    color: white;
    border-radius: 15px;
    border: 1px solid rgba(255, 255, 255, 0.4);
    overflow: hidden;
}


.RoomCover-icon {
    display: flex;
    margin-right: 20px;
}

.RoomCover-roomName {
    display: flex;
    font-size: 1.2rem;
}