.SamanthaInput {
    display: flex;
    align-items: flex-end;
}

.SamanthaInput-text {
    padding: 20px;
    border-radius: 10px;
    color: white;
    background-color: #333333;
    width: 800px;
    font-size: 22px;
}

.SamanthaInput-text:focus {
    outline: 0 solid transparent;
}

[contenteditable=true]:empty:not(:focus):before {
    content: attr(placeholder);
    font-style: italic;
    text-align: center;
    pointer-events: none;
}