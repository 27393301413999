
.Dialog-box {
    position: relative;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    transition: width 0.15s ease, height 0.15s ease;
    border: 1px solid black;
    overflow: hidden;
}

.Dialog .Dialog--small {
    width: 400px;
}

.Dialog .Dialog--medium {
    width: 500px;
}

.Dialog .Dialog--large {
    width: 700px;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

